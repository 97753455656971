(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("modularConfigurationModalController", modularConfigurationModalController);

    modularConfigurationModalController.$inject = [
        "$mdDialog",
        "$rootScope",
        "$translate",
        "$http",
        "errorHandling",
        "addonName",
        "enabled",
        "alreadyConfigured",
        "domainAdminCanOverride"
    ];

    function modularConfigurationModalController(
        $mdDialog,
        $rootScope,
        $translate,
        $http,
        errorHandling,
        addonName,
        enabled,
        alreadyConfigured,
        domainAdminCanOverride
    ) {
        var vm = this;

        vm.addonName = addonName; // "owncloud" (passed in or set as needed)
        vm.activeSections = [];
        vm.bannerImage = "";
        vm.addonEnabled = enabled;
        vm.errorMessage = null;
        vm.cancel = cancel;
        vm.save = save;
        vm.alreadyConfigured = false;
        vm.deleteOwncloudConnection = deleteOwncloudConnection;
        vm.domainAdminCanOverride = domainAdminCanOverride;

        activate();

        async function activate() {
            await initializeOwnCloudSettings();
            setBannerImage();
        }

        // Hard-code or detect OwnCloud fields for your UI
        async function initializeOwnCloudSettings() {
            // If your logic confirms it’s already configured:
            if (await isOwncloudConnected()) {
                vm.alreadyConfigured = true;
            }
            vm.activeSections = [];
            switch (vm.addonName.toLowerCase()) {
                case "owncloud":

                    vm.activeSections = [
                        {
                            title: $translate.instant("OWNCLOUD"),
                            settings: [
                                {
                                    key: "name",
                                    type: "input",
                                    label: $translate.instant("DISPLAY_NAME"),
                                    value: ""
                                },
                                {
                                    key: "url",
                                    type: "input",
                                    label: $translate.instant("SERVER_ADDRESS"),
                                    value: ""
                                },
                                {
                                    key: "username",
                                    type: "input",
                                    label: $translate.instant("USERNAME"),
                                    value: ""
                                },
                                {
                                    key: "password",
                                    type: "password",
                                    label: $translate.instant("PASSWORD"),
                                    value: ""
                                }
                            ]
                        }
                    ];
                    break;
            }
        }

        // Check if OwnCloud is connected
        async function isOwncloudConnected() {
            return $http.get("/api/v1/settings/connected-services")
                .then(function (response) {
                    if (response.data.success && Array.isArray(response.data.connectedService)) {
                        return response.data.connectedService.some(service => service.type === 10);
                    }
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }

        // Obtain the existing OwnCloud service ID
        async function getOwncloudID() {
            return $http.get("/api/v1/settings/connected-services")
                .then(function (response) {
                    if (response.data.success && Array.isArray(response.data.connectedService)) {
                        var svc = response.data.connectedService.find(s => s.type === 10);
                        return svc ? svc.id : null;
                    }
                    return null;
                })
                .catch(function () {
                    return null;
                });
        }

        // Delete the OwnCloud connection
        async function deleteOwncloudConnection() {
            const id = await getOwncloudID();
            if (!id) return false;

            const data = { serviceIDs: [id] };
            $http.post("/api/v1/settings/connected-services-delete", data)
                .then(function (response) {
                    if (response.data.success) {
                        cancel();
                        return true;
                    }
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }

        // Attempt to verify or save OwnCloud settings
        function checkOwncloudSettings(settings) {
            var testParams = {
                url: settings.ownCloud.url,
                username: settings.ownCloud.username,
                password: settings.ownCloud.password,
                name: settings.ownCloud.name
            };

            // Adjust this endpoint to your environment
            $http.post("/api/v1/settings/owncloud-test", testParams)
                .then(function (result) {
                    if (!result.data.success) {
                        errorHandling.report(result.data.message);
                    } else {
                        // Possibly close the dialog or just confirm success
                        $mdDialog.hide(testParams);
                    }
                })
                .catch(errorHandling.report);
        }
        
        // Banner image
        function setBannerImage() {
            var defaultImage = "img/MarketPlace/default_banner.webp";
            var customImage = `img/MarketPlace/${vm.addonName}_banner.webp`;

            $http.head(customImage)
                .then(() => {
                    vm.bannerImage = customImage;
                })
                .catch(() => {
                    vm.bannerImage = defaultImage;
                });
        }

        function processSectionSettings(settings, section, key) {
            section.settings.forEach(field => {
                settings[key][field.key] = field.value;
            });
            // Save/verify
            checkOwncloudSettings(settings);
        }

        function save() {
            // Build the object to submit
            var settings = {
                ownCloud: {
                    enabled: vm.addonEnabled
                }
            };

            // Fill from user input
            vm.activeSections.forEach(section => {
                if (section.title === $translate.instant("OWNCLOUD")) {
                    processSectionSettings(settings, section, "ownCloud");
                }
            });
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();