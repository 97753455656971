(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userCloudStorageConnectController", userCloudStorageConnectController);

    function userCloudStorageConnectController(
        $rootScope, $scope, $mdDialog, $filter, $http, $state, $window,
        $timeout, modalService, popupService, coreData, errorHandling,
        providerId, providerName, alreadyConfigured, enabled, DomainAdminCanOverride
    ) {
        var vm = this;
        // Initialize scope variables
        $scope.providerId = providerId;
        $scope.providerName = providerName;
        $scope.step = 1;
        $scope.folderName = $scope.folderName || "";
        $scope.alreadyConfigured = alreadyConfigured;
        $scope.enabled = enabled;
        $scope.enableItem = enableItem;
        $scope.deleteConnection = deleteConnection;
        $scope.DomainAdminCanOverride = DomainAdminCanOverride;
        $scope.isDomainAdmin = coreData.user.isDomainAdmin;
        // Ensure folderName persists
        $scope.persistFolderName = function () {
            $scope.folderName = $scope.folderName || "";
        };

        $scope.accountTypes = [
            {
                translatable: "DROPBOX",
                id: 2,
                imgSrc: "img/product-icons/dropbox_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_DROPBOX"
            },
            {
                translatable: "GOOGLEDRIVE",
                id: 9,
                imgSrc: "img/product-icons/drive_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_GOOGLE_DRIVE"
            },
            {
                translatable: "LEITZCLOUD",
                id: 7,
                imgSrc: "img/product-icons/leitzcloud_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_LEITZCLOUD"
            },
            {
                translatable: "ONEDRIVE",
                id: 4,
                imgSrc: "img/product-icons/onedrive_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_ONEDRIVE_ACCESS"
            },
            {
                translatable: "OWNCLOUD",
                id: 10,
                imgSrc: "img/product-icons/owncloud_icon.png",
                useOAuth: false,
                defaultDisplayKey: "MY_OWNCLOUD"
            },
            {
                translatable: "VBOXX",
                id: 8,
                imgSrc: "img/product-icons/vboxx_icon.png",
                useOAuth: true,
                defaultDisplayKey: "MY_VBOXX"
            }
        ];

        vm.modalServiceCallback = modalServiceCallback;
        $scope.cancel = cancel;
        $scope.openService = openService;

        activate();

        // Activate Function
        function activate() {
            modalService.setModalCallback(vm.modalServiceCallback);

            for (let i = 0; i < $scope.accountTypes.length; i++) {
                if ($scope.providerId.toUpperCase() === $scope.accountTypes[i].translatable) {
                    let type = $scope.accountTypes[i];
                    type.translatable = $filter("translate")(type.translatable);
                    if (
                        !$scope.alreadyConfigured && $scope.enabled) {
                        openService(type);
                    } else if ($scope.enabled && $scope.alreadyConfigured) {
                        $scope.step = 3;
                    }
                    return;
                }
            }
        }

        function addProvider(modalSuccess) {
            debugger;
            var key = null
            $scope.accountTypes.forEach(function (type) {
                if (type.id === modalSuccess.type.id) {
                    console.log(type.id)
                    console.log(modalSuccess.type.id)
                    key = type.id
                    
                } 
            })
            if (key === null) {
                return null
            }
            let params = JSON.stringify({
                code: modalSuccess.code,
                name: modalSuccess.name,
                providerKey: key,
            });
            switch (key) {
                default:
                    break;
                case 2: // DROPBOX
                    $http.post("~/api/v1/settings/dropbox-put", params)
                    break;
                case 4: // ONEDRIVEV2
                    $http.post("~/api/v1/settings/one-drive-put", params)
                    break;
                case 7: // Leitzcloud
                    $http.post("~/api/v1/settings/leitzcloud-put", params)
                    break;
                case 8: // vBoxx
                    $http.post("~/api/v1/settings/vboxx-put", params)
                    break;
                case 9: // Google Picker
                    $http.post("~/api/v1/settings/google-put", params)
                    break;
            }
            cancel();
            return true; 
        }

        async function getCloudIds() {
            // Get the ID from the accountTypes based on the providerId.
            const accountType = $scope.accountTypes.find(
                (type) => $scope.providerId.toUpperCase() === type.translatable.toUpperCase()
            );

            if (!accountType) {
                return null; // Return null if no matching account type is found.
            }

            const id = accountType.id;

            try {
                // Fetch connected services data from the API.
                const response = await $http.get('/api/v1/settings/connected-services');

                if (response.data.success) {
                    // Find the connected service matching the id.
                    const connectedService = response.data.connectedService.find(
                        (service) => service.type === id
                    );

                    return connectedService ? connectedService.id : null;
                }
                return null; // Return null if success flag is false.
            } catch (error) {
                // Handle errors gracefully
                return null;
            }
        }

        async function deleteConnection() {
            const id = await getCloudIds();
            const data = { serviceIDs: [id] };
            $http.post('/api/v1/settings/connected-services-delete', data)
                .then(function (response) {
                    if (response.data.success) {
                        cancel();
                        return true;
                    }
                })
                .catch(function (error) {
                    return false;
                });
        }

        // Save Function
        $scope.save = function () {
            if (!$scope.folderName.trim()) {
                alert("Please fill in the display name.");
                return;
            }

            if ($scope.currentType && $scope.currentType.useOAuth) {
                const returnArgs = {
                    name: $scope.folderName,
                    type: $scope.currentType,
                    code: $scope.returnCode
                };

                addProvider(returnArgs);
            }
        };

        // Cancel Function
        function cancel() {
            $mdDialog.cancel({ reason: "cancel" });
            modalService.setModalCallback(null);
        }

        // Open Service Function
        function openService(type) {
            if (!type) {
                // Find the account type that matches the providerId
                for (let i = 0; i < $scope.accountTypes.length; i++) {
                    if ($scope.providerId.toUpperCase() === $scope.accountTypes[i].translatable.toUpperCase()) {
                        type = $scope.accountTypes[i];
                        type.translatable = $filter("translate")(type.translatable);
                        break; // Exit the loop once the correct type is found
                    }
                }

                // If no matching account type is found, return early
                if (!type) {
                    console.error("No matching account type found for providerId:", $scope.providerId);
                    return;
                }
            }

            if (type.useOAuth) {
                doOAuth(type);
            } else {
                $scope.currentType = type;
                $scope.folderName =
                    $filter("translate")(type.defaultDisplayKey) ||
                    type.defaultDisplayKey ||
                    "Unnamed Folder";
                $scope.step = 2;
                $scope.persistFolderName();
            }
        }

        // Modal Callback
        function modalServiceCallback() {
            $mdDialog.cancel({ reason: "cancel" });
        }

        function enableItem(maybe) {
            debugger;
            $rootScope.spinner.show();
            var item = providerId;
            var updatedSettings = { enabled: !item.enabled };

            if (item.toLowerCase() === "onlyoffice") {
                $http.post("/api/v1/marketplace/setOnlyOfficeSettings", updatedSettings)
                    .then(() => {
                        item.enabled = updatedSettings.enabled;
                    })
                    .catch(errorHandling.report)
                    .finally($rootScope.spinner.hide);
            } else if (item.toLowerCase() === "collabora") {
                $http.post("/api/v1/marketplace/setCollaboraSettings", updatedSettings)
                    .then(() => {
                        item.enabled = updatedSettings.enabled;
                    })
                    .catch(errorHandling.report)
                    .finally(function () {
                        $rootScope.spinner.hide();
                        location.reload();
                    });
            } else {
                var toggleRequest = {
                    ProviderKey: item,
                    Enable: maybe
                };

                $http.post("/api/v1/marketplace/ToggleCloudStorageProvider", toggleRequest)
                    .then(() => {})
                    .catch(errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
            cancel();
        }

        function doOAuth(type) {
            $scope.currentType = undefined;
            $scope.returnCode = undefined;

            const redirect = coreData.baseUrl + "/interface/connect-redirect";
            const params = JSON.stringify({ redirectUri: redirect });

            const popupFeatures =
                "width=500,height=600,menubar=no,toolbar=no,location=no,scrollbars=yes,resizable=yes,left=200,top=100";
            const newWindow = window.open("", "OAuthPopup", popupFeatures);

            if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") {
                errorHandling.report("Popup blocked! Please enable popups for this site.");
                return;
            }

            $window.handleConnectClose = function (result) {
                if (result.code) {
                    $scope.$apply(function () {
                        $scope.returnCode = result.code;
                        $scope.step = 2;
                    });
                    $window.focus();
                }
                $window.handleConnectClose = null;
            };

            switch (type.id) {
                case 2:
                    $http.post("~/api/v1/settings/dropbox-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 4:
                    $http.post("~/api/v1/settings/microsoft-redirect/OneDrive", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 7:
                    $http.post("~/api/v1/settings/leitzcloud-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 8:
                    $http.post("~/api/v1/settings/vboxx-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                case 9:
                    $http.post("~/api/v1/settings/google-redirect", params)
                        .then(onRedirectApiSuccess, onRedirectApiFailure);
                    break;
                default:
                    newWindow.close();
                    break;
            }

            function onRedirectApiFailure(failure) {
                errorHandling.report(failure);
                newWindow.close();
            }

            function onRedirectApiSuccess(success) {
                $scope.currentType = type;
                $scope.folderName =
                    $filter("translate")(type.defaultDisplayKey) ||
                    type.defaultDisplayKey ||
                    "Unnamed Folder";
                newWindow.location = success.data.result;
            }
        }
    }
})();